export const ParcoursItemStatus = {
	locked: 'locked',
	open: 'open',
	onboarding: 'onboarding',
	started: 'started',
	ongoing: 'ongoing',
	completed: 'completed',
	cancelled: 'cancelled',
	paused: 'paused',
};
