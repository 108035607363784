var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-replay-and-see-again"},[_c('div',{staticClass:"btn-redo-container"},[_c('a',{attrs:{"href":_vm.$router.resolve({
					name: 'Story',
					params: {
						idFormation: _vm.$route.params.idFormation,
						idExercice: _vm.$route.params.idExercice,
						idStory: _vm.story.id,
					},
				}).href},on:{"click":function($event){return _vm.replayStory()}}},[_c('v-btn',{attrs:{"outlined":"","large":"","width":"100%"}},[_vm._v("Rejouer la règle")])],1)]),_c('div',{staticClass:"btn-see-rules-container"},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":""},on:{"click":function($event){return _vm.displayRulePanel()}}},[_c('div',{staticClass:"title-v-expansion-panel-header"},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-subdirectory-arrow-right")]),_c('p',[_vm._v("Relire l'astuce")])],1)]),_c('v-expansion-panel-content',[_vm._l((_vm.story.infos.questions_id[0].cours_id),function(cours,coursIndex){return _c('div',{key:coursIndex},[_c('span',{domProps:{"innerHTML":_vm._s(cours.description)}})])}),(_vm.story.infos.rule_tags_id.ressource_link)?_c('v-btn',{staticClass:"fiche-btn",attrs:{"href":_vm.story.infos.rule_tags_id.ressource_link,"target":"_blank","outlined":"","color":"black"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-eye")]),_vm._v(" Voir la fiche ")],1):_vm._e()],2)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }