<template>
	<v-card class="shadow-blue pa30">
		<h2>Assiduité</h2>
		<p class="ortho-text-gray">Ta productivité orthographique s'améliore de jour en jour, sois régulier</p>
		<p class="wordingStrike ortho-text-green text-center font-weight-black">
			<span> <span class="mr-1">🔥</span>{{ strike }} </span>
			<span>jour<span v-if="strike > 1" style="font-size: 20px">s</span> d'affilée</span>
		</p>
	</v-card>
</template>

<script>
export default {
	name: 'ScoreFinal',
	props: {
		totalScoreInPercent: Number,
		strike: Number,
	},
};
</script>

<style lang="scss" scoped>
.wordingStrike {
	span:first-child {
		font-size: 30px;
	}
	span:nth-child(2) {
		font-size: 20px;
	}
}
</style>
