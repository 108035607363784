<template>
	<div class="d-flex justify-space-around align-center pl-0 pr-0 pl-md-7 pr-md-7 flex-column flex-md-row">
		<div class="d-flex align-center divScoreIndications mb-2 mb-md-0">
			<span class="pointScore skyBlue mr-2"></span>
			<p class="mb-0 d-flex flex-column text-right">
				<span>{{ Math.ceil(totalScoreInPercent) }} %</span>
				<span>Ton score</span>
			</p>
		</div>
		<SocialCircleJauge
			:height="250"
			:color="['#34e5c8', '#989FA9']"
			:data="[totalScoreInPercent, randomNumberMasteryPercentage]"
		></SocialCircleJauge>
		<div class="d-flex align-center divScoreIndications">
			<p class="mb-0 d-flex flex-column">
				<span>{{ randomNumberMasteryPercentage }} %</span>
				<span>Moyenne</span>
			</p>
			<span class="pointScore grey ml-2"></span>
		</div>
	</div>
</template>

<script>
import SocialCircleJauge from '../charts/SocialCircleJauge';
import moment from 'moment';

export default {
	name: 'PerformanceChart',
	props: {
		totalScoreInPercent: Number,
	},
	components: {
		SocialCircleJauge,
	},
	computed: {
		randomNumberMasteryPercentage() {
			let day = moment().day();

			if (day % 2 == 0) {
				return 83;
			} else {
				return 81;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pointScore {
	display: block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	&.skyBlue {
		background: #34e5c8;
	}
	&.grey {
		background: rgba(169, 169, 169, 0.5);
	}
}
.divScoreIndications > p > span:first-child {
	font-size: 25px;
	font-weight: bold;
}
@media only screen and (max-width: 600px) {
	.divScoreIndications > p > span:first-child {
		font-size: 15px;
		font-weight: bold;
	}
	.divScoreIndications > p > span:nth-child(2) {
		font-size: 10px;
	}
}
</style>
