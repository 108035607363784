<template>
	<v-card class="shadow-blue pa30">
		<h2>Performance à la loupe</h2>
		<p class="ortho-text-gray">Tes points s'additionnent à ton score de progression</p>
		<div v-for="story in stories" :key="story.id" class="containerStoriesScore">
			<div v-for="result in resultToDisplay" :key="result.story_id" class="mb-5">
				<v-container v-if="result.story_id == story.id">
					<div class="rules-by-story-element-container">
						<Rules :story="story" />
						<Charts :result="result" />
					</div>
					<PanelsAstuces :story="story" />
				</v-container>
			</div>
		</div>
	</v-card>
</template>

<script>
import Rules from './score-stories/Rules';
import Charts from './score-stories/Charts';
import PanelsAstuces from './score-stories/PanelsAstuces';

export default {
	name: 'ScoreByStories',
	props: {
		stories: Array,
		resultToDisplay: Array,
	},
	components: {
		Rules,
		Charts,
		PanelsAstuces,
	},
};
</script>

<style>
.containerStoriesScore:not(:last-child) {
	border-bottom: 1px solid gainsboro;
	margin-bottom: 15px;
}
.rules-by-story-element-container {
	display: flex;
	flex-direction: row;
}
@media only screen and (max-width: 600px) {
	.rules-by-story-element-container {
		display: flex;
		flex-direction: column;
	}
}
</style>
