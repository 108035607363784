export const modalData = {
	title: 'Un grand merci pour votre avis !',
	descriptions: [
		{ icon: '&#128260;', content: "C'est votre avis qui nous permet de nous améliorer tous les jours !" },
		{ icon: '&#129321;', content: 'Nous vous avons ajouté à notre liste de super utilisateur' },
		{ icon: '❤️', content: "Toute l'équipe vous remercie du fond du coeur" },
	],
	ctaContinue: 'Ne pas continuer',
	ctaBtn: 'Continuer votre avis sur Google',
	hrefAndroidApp: 'https://play.google.com/store/apps/details?id=com.orthographiq.app.twa',
	hrefGoogleMap: 'https://g.page/r/CdfTAPBX0ImDEBM/review',
	modalImage: 'GoogleReview.png',
};
