<template>
	<v-row justify="center">
		<v-dialog v-model="displayCondition" persistent content-class="review-app-dialog">
			<v-card id="review-app-card">
				<div class="modal-image-container">
					<img :src="getImgUrl(data.modalImage)" />
				</div>
				<div class="modal-content">
					<v-card-title class="card-title text-h4 font-weight-black"> {{ data.title }} </v-card-title>
					<v-card-text class="modal-detail">
						<ul>
							<li v-for="(description, index) in data.descriptions" :key="index">
								<span v-html="description.icon"></span>
								{{ description.content }}
							</li>
						</ul>
					</v-card-text>
					<v-card-actions class="modal-actions">
						<button @click="closeModalAndSaveRating()">
							<b>{{ data.ctaContinue }}</b>
						</button>
						<v-btn
							color="black"
							x-large
							dark
							class="modal-redirect-link-button"
							@click="closeModal()"
							:href="renderRedirectLink()"
							target="_blank"
						>
							<b>{{ data.ctaBtn }}</b>
						</v-btn>
					</v-card-actions>
				</div>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { modalData } from './data.js';
import { detectAndroidUserByReferrer, getImgUrl, setZindexByClassNames } from '../../../utils/helper';

export default {
	name: 'ModalForReviewApp',
	props: {
		noFeedbacksExercices: Boolean,
		totalScoreInPercent: Number,
		rating: Number,
	},
	data: () => {
		return {
			data: modalData,
			isUserOnAndroidApp: detectAndroidUserByReferrer(),
		};
	},
	computed: {
		displayCondition() {
			return this.noFeedbacksExercices && this.totalScoreInPercent >= 80 && this.rating == 5;
		},
	},
	mounted: function () {
		if (window.innerWidth >= 960) {
			setZindexByClassNames('navbar', 210); // 210 is higher enough than v-dialog
			setTimeout(() => setZindexByClassNames('trial-finished-alert', 210), 300);
		}
	},
	destroyed: function () {
		setZindexByClassNames(['navbar', 'trial-finished-alert'], 0);
	},
	methods: {
		detectAndroidUserByReferrer,
		getImgUrl,
		setZindexByClassNames,
		renderRedirectLink() {
			if (this.isUserOnAndroidApp) return this.data.hrefAndroidApp;
			else return this.data.hrefGoogleMap;
		},
		async closeModalAndSaveRating() {
			try {
				if (process.env.VUE_APP_MIXPANEL == 'production') {
					this.$mixpanel.track('exercice-feedback-modal-dismiss');
				}
				this.$parent.$data.submitFeedback = true;
				this.$http
					.post('/api/exercice/feedback', {
						idFormation: this.$route.params.idFormation,
						idExercice: this.$route.params.idExercice,
						rating: this.rating,
						comments: this.$parent.$data.comments,
					})
					.catch((error) => {
						console.log(error);
					});
				await this.$store.dispatch('exercice/resetNoFeedbacksExercices');
			} catch (error) {
				console.log('error: ', error);
			}
		},
		async closeModal() {
			try {
				if (process.env.VUE_APP_MIXPANEL == 'production') {
					this.$mixpanel.track('exercice-feedback-modal-accept');
				}
				await this.$store.dispatch('exercice/resetNoFeedbacksExercices');
			} catch (error) {
				console.log('error: ', error);
			}
		},
	},
};
</script>

<style lang="scss" scope>
.review-app-dialog {
	max-width: 50%;
	overflow-y: hidden;
}

#review-app-card {
	.card-title {
		word-break: keep-all;
		padding-bottom: 10px;
		padding-top: 5px;
	}

	.modal-image-container {
		height: fit-content;
		background-color: #eee5db;

		img {
			position: relative;
			left: 0;
			bottom: -7px;
			max-width: 100%;
			max-height: 250px;
			margin-top: 50px;
		}
	}

	.modal-content {
		padding: 38px 22px 32px 22px;
	}

	.modal-detail {
		padding: 5px 16px;
		line-height: 2;
		font-size: 18px;
		ul {
			list-style: none;
			padding-left: 0;
			li {
				span {
					font-size: 20px;
					margin-right: 4px;
				}
			}
		}
	}

	.modal-actions {
		justify-content: end;

		button {
			margin-right: 20px;
			font-size: 20px;
			color: #999999;
		}

		.modal-redirect-link-button {
			border-radius: 10px;
			text-transform: none;
			font-size: 18px;
		}
	}
}

@media screen and (min-width: 1200px) {
	.review-app-dialog {
		margin-top: 100px;
		max-height: 85% !important;
	}
}

@media screen and (min-width: 950px) {
	.review-app-dialog {
		margin-top: 140px;
	}
}

@media screen and (min-width: 1200px) and (max-height: 820px) {
	.modal-image-container {
		img {
			width: 30% !important;
		}
	}
}

@media screen and (min-width: 760px) and (max-height: 500px) {
	.modal-image-container {
		img {
			display: none;
		}
	}
}

@media screen and (min-width: 800px) and (max-height: 410px) {
	.review-app-dialog {
		max-height: 100% !important;
	}
}

@media screen and (max-width: 1300px) {
	.review-app-dialog {
		max-width: 60%;
	}
	#review-app-card {
		.modal-content {
			padding: 14px;
		}
		.modal-actions {
			flex-direction: column-reverse;
			justify-content: center;

			button {
				margin-top: 10px;
			}
		}
	}
}

@media screen and (max-width: 1080px) {
	.review-app-dialog {
		max-width: 75%;
	}
}

@media screen and (max-width: 790px) {
	.review-app-dialog {
		max-width: 90%;
		margin: 0;
	}

	#review-app-card {
		.v-card__title {
			line-height: 1.5rem !important;
			font-size: 1.5rem !important;
		}
		.modal-image-container {
			img {
				margin-top: 15px;
			}
		}

		.modal-actions {
			button {
				font-size: 16px;
			}

			.modal-redirect-link-button {
				padding: 20px 32px;
				font-size: 16px;
				width: 70%;
				height: 40px;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	#review-app-card {
		.v-card__title {
			line-height: 2rem !important;
			font-size: 1.6rem !important;
		}

		.modal-content {
			padding: 8px;
		}

		.modal-detail {
			line-height: 1.6;

			ul {
				li:nth-child(3) {
					display: none;
				}
			}
		}

		.modal-actions {
			margin-top: 10px;
			.modal-redirect-link-button {
				width: 100%;
				border-radius: 6px;
			}
		}
	}
}
</style>
