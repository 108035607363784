<template>
	<v-card class="shadow-blue pa30">
		<h2>Performance du jour</h2>
		<p>
			<span v-if="totalScoreInPercent >= randomNumberMasteryPercentage" class="ortho-text-gray"
				>Ton taux de bonnes réponses est au-dessus de la moyenne, continue comme ça !
			</span>
			<span v-else class="ortho-text-gray">Ton taux de bonnes réponses est en-dessous de la moyenne, persévère ! </span>
		</p>
		<PerformanceChart :totalScoreInPercent="totalScoreInPercent"></PerformanceChart>
	</v-card>
</template>

<script>
import PerformanceChart from '../../../performance-chart/Index';
import moment from 'moment';

export default {
	name: 'ChartsResultExercice',
	props: {
		totalScoreInPercent: Number,
	},
	components: {
		PerformanceChart,
	},
	computed: {
		randomNumberMasteryPercentage() {
			let day = moment().day();

			if (day % 2 == 0) {
				return 70;
			} else {
				return 60;
			}
		},
	},
};
</script>
