<template>
	<v-col xl="6" md="6" sm="12" cols="12" class="text-center text-md-left">
		<h6 class="ortho-text-gray text-uppercase font-weight-bold mb-5">
			{{ story.infos.rule_tags_id.family_tag_id.name }}
		</h6>
		<div class="d-flex align-center justify-center justify-md-start flex-column flex-md-row">
			<!-- <v-tooltip color="black" top>
				<template v-slot:activator="{ on }">
					<v-toolbar-items>
						<a
							:href="
								$router.resolve({
									name: 'Story',
									params: {
										idFormation: $route.params.idFormation,
										idExercice: $route.params.idExercice,
										idStory: story.id,
									},
								}).href
							"
							@click="replayStory()"
						>
							<v-btn v-on="on" icon color="#2dc7ae" class="hvr-shrink mb-3 mb-md-0">
								<v-icon class="fs20">fas fa-undo</v-icon>
							</v-btn>
						</a>
					</v-toolbar-items>
				</template>
				<span>Rejouer la règle</span>
			</v-tooltip> -->
			<h2 class="text-uppercase">{{ story.infos.rule_tags_id.name }}</h2>
		</div>
		<h5 class="mt-4 mb-4 text-uppercase ortho-text-gray">Maîtrisée par {{ randomNumber }} % utilisateurs</h5>
	</v-col>
</template>

<script>
export default {
	name: 'Rules',
	props: {
		story: Object,
	},
	data() {
		return {
			dialog: false,
		};
	},
	computed: {
		randomNumber() {
			const min = 70;
			const max = 80;
			var number = Math.floor(Math.random() * (max - min + 1)) + min;
			return number;
		},
	},
	// methods: {
	// 	replayStory() {
	// 		if (process.env.VUE_APP_MIXPANEL == 'production') {
	// 			this.$mixpanel.track('exercice-replayStory');
	// 		}
	// 	},
	// },
};
</script>

<style lang="scss" scoped>
.family-tag-name {
	font-size: 15px;
}
a {
	color: #15c39a !important;
	text-decoration: inherit;
}
</style>
