<template>
	<div class="d-flex justify-center flex-column flex-md-row">
		<v-btn
			:class="{
				'btn-background-black': formation.infinity_exercice == false,
				'btn-background-black': totalScoreInPercent < 80,
			}"
			class="hvr-grow actionButton mr-0 mr-md-4 mb-3 mb-md-0"
			large
			outlined
			@click="refreshPage()"
			>Refaire la session</v-btn
		>
		<div v-if="canShowNextExerciseBtn" class="mr-0 mr-md-4 mb-3 mb-md-0">
			<div v-if="this.nextExo">
				<a :href="'/exercices/' + $route.params.idFormation + '/' + this.nextExo" style="color: black">
					<v-btn class="hvr-grow actionButton" dark large width="100%" @click="$confetti.stop()">
						Session suivante
					</v-btn>
				</a>
			</div>
		</div>
		<a :href="'/dashboard'">
			<v-btn class="hvr-grow actionButton" outlined large width="100%" @click="goToDashboard()">Mon espace perso</v-btn>
		</a>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'ButtonsFinal',
	computed: {
		...mapGetters('exercice', {
			formation: 'formation',
			allExerciceFromFormationCategory: 'allExerciceFromFormationCategory',
			exercice: 'exerciceInfos',
		}),
		canShowNextExerciseBtn() {
			return (
				this.totalScoreInPercent >= 80 &&
				this.formation.formation_finished == false &&
				this.formation.infinity_exercice == true &&
				this.allExerciceFromFormationCategory.exact_nb_exos > this.exercice.position_list
			);
		},
	},
	data() {
		return {
			nextExo: null,
		};
	},
	props: {
		totalScoreInPercent: Number,
	},
	async created() {
		if (
			this.formation.infinity_exercice == true &&
			this.allExerciceFromFormationCategory.exact_nb_exos > this.exercice.position_list
		)
			await this.getNextExerciseId();
	},
	methods: {
		async getNextExerciseId() {
			try {
				const res = await this.$http.get(
					'/api/exercice/next/' + this.$route.params.idFormation + '/' + this.$route.params.idExercice
				);
				this.nextExo = res.data.nextExerciceId;
			} catch (error) {
				console.error('No next exercise ID');
			}
		},
		refreshPage() {
			this.$confetti.stop();
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('exercice-replayExercice');
			}
			if (this.$router.currentRoute.name == 'Exercice') {
				location.reload();
			} else {
				this.$router.push({
					name: 'Exercice',
					params: {
						idFormation: this.$route.params.idFormation,
						idExercice: this.$route.params.idExercice,
					},
				});
			}
		},
		goToDashboard() {
			this.$confetti.stop();
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('exercice-goToDashboard');
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.btn-background-black {
	background-color: black !important;
	color: white !important;
}
::v-deep {
	.v-btn__content {
		height: 100%;
	}
}
</style>
