import { render, staticRenderFns } from "./Strike.vue?vue&type=template&id=61eb2d81&scoped=true&"
import script from "./Strike.vue?vue&type=script&lang=js&"
export * from "./Strike.vue?vue&type=script&lang=js&"
import style0 from "./Strike.vue?vue&type=style&index=0&id=61eb2d81&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61eb2d81",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
