<template>
	<v-col xl="6" md="6" sm="12" cols="12" class="d-flex justify-center align-center text-center">
		<v-row class="d-flex justify-center align-center text-center" style="position: relative">
			<div
				style="position: absolute; top: 40px"
				:class="{
					greenText: sum(result.questions_id) * 10 < 50,
					goldText: sum(result.questions_id) * 10 == 50,
				}"
			>
				<strong>{{ sum(result.questions_id) * 10 }}</strong>

				<img
					class="lighteningIcons"
					v-if="sum(result.questions_id) * 10 < 50"
					src="@/assets/icons/lightening-green.svg"
				/>
				<img class="lighteningIcons" v-else src="@/assets/icons/lightening-gold.svg" />

				<span>/50</span>
			</div>
			<v-progress-circular
				rotate="270"
				size="100"
				width="15"
				:value="(sum(result.questions_id) / result.questions_id.length) * 100"
				:color="colorRules(sum(result.questions_id) / result.questions_id.length)"
			>
			</v-progress-circular>
		</v-row>
	</v-col>
</template>

<script>
import moment from 'moment';

export default {
	name: 'Charts',
	props: {
		result: Object,
	},
	methods: {
		sum(questionsResults) {
			let total = 0;
			questionsResults.map((element) => {
				if (element.is_good_answer == true) {
					total++;
				}
			});
			if (total == 0) {
				return 0;
			} else {
				return total;
			}
		},
		randomNumberMemoryPercentage() {
			let day = moment().day();

			if (day % 2 == 0) {
				return 70;
			} else {
				return 90;
			}
		},
		colorRules(value) {
			if (value == 1) {
				return '#FFD15E';
			} else {
				return '#34e5c8';
			}
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.v-progress-circular__underlay {
		stroke: rgba(242, 242, 242, 0.85);
	}
}
.myScoreContainer {
	text-align: right;
}
.averageScoreContainer {
	text-align: left;
}
@media only screen and (max-width: 900px) {
	.myScoreContainer {
		text-align: center;
	}
	.averageScoreContainer {
		text-align: center;
	}
}
</style>
