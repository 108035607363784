<template>
	<div class="container-replay-and-see-again">
		<div class="btn-redo-container">
			<a
				:href="
					$router.resolve({
						name: 'Story',
						params: {
							idFormation: $route.params.idFormation,
							idExercice: $route.params.idExercice,
							idStory: story.id,
						},
					}).href
				"
				@click="replayStory()"
			>
				<v-btn outlined large width="100%">Rejouer la règle</v-btn>
			</a>
		</div>
		<div class="btn-see-rules-container">
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header expand-icon="" @click="displayRulePanel()">
						<div class="title-v-expansion-panel-header">
							<v-icon small color="black">mdi-subdirectory-arrow-right</v-icon>
							<p>Relire l'astuce</p>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div v-for="(cours, coursIndex) in story.infos.questions_id[0].cours_id" :key="coursIndex">
							<span v-html="cours.description"></span>
						</div>
						<v-btn
							v-if="story.infos.rule_tags_id.ressource_link"
							class="fiche-btn"
							:href="story.infos.rule_tags_id.ressource_link"
							target="_blank"
							outlined
							color="black"
						>
							<v-icon left> mdi-eye</v-icon>
							Voir la fiche
						</v-btn>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PanelsAstuces',
	props: {
		story: Object,
	},
	methods: {
		displayRulePanel() {
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('exercice-displayRulePanel');
			}
		},
		replayStory() {
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('exercice-replayStory');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.v-expansion-panel-content__wrap {
		padding: 0px;
	}
}

.v-expansion-panel-header {
	padding: 0px;
	border-radius: 4px;
	p {
		margin: 0px;
	}
}

.title-v-expansion-panel-header {
	display: flex;
	align-items: left;
}

.btn-redo-container {
	display: flex;
	margin-top: 5px;
}

.container-replay-and-see-again {
	margin-left: 12px;
	margin-right: 12px;
}

.fiche-btn {
	margin-top: 20px;
}

@media only screen and (max-width: 900px) {
	.container-replay-and-see-again {
		margin-left: 0px;
		margin-right: 0px;
	}

	.v-expansion-panel-header {
		display: flex;
		flex-direction: column;
		p {
			margin: 0px;
			text-align: center;
		}
	}

	.title-v-expansion-panel-header {
		display: flex;
		align-items: center;
	}

	.btn-redo-container {
		display: block;
		margin-top: 30px;
	}

	.fiche-btn {
		width: 100%;
	}
}
</style>
