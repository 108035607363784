<template>
	<div id="chart">
		<apexchart type="radialBar" :height="height" :options="chartOptions" :series="data" :color="color"></apexchart>
	</div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';

export default {
	name: 'SocialCircleJauge',
	components: {
		apexchart: VueApexCharts,
	},
	props: {
		data: Array,
		color: Array,
		height: Number,
		isResponsive: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			series: this.data,
			chartOptions: {
				chart: {
					height: 390,
					type: 'radialBar',
				},
				states: {
					hover: {
						filter: {
							type: 'none',
						},
					},
					active: {
						filter: {
							type: 'darken',
							value: 1,
						},
					},
				},
				labels: ['Mon score', 'Moyenne'],
				plotOptions: {
					radialBar: {
						offsetY: -20,
						startAngle: 0,
						endAngle: 360,
						hollow: {
							margin: 0,
							size: '50%',
							background: 'transparent',
							image: undefined,
						},
						dataLabels: {
							name: {
								show: false,
							},
							value: {
								show: false,
							},
						},
					},
				},
				colors: this.color,
				responsive: this.isResponsive
					? [
							{
								breakpoint: 600,
								options: {
									legend: {
										show: false,
									},
								},
							},
					  ]
					: [],
			},
		};
	},
};
</script>
